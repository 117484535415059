import {
  CHANGE_HIDE_ADDRESS,
  CHANGE_OUT_OF_STOCK,
  CLEANUP,
  DONE_LOADING,
  GIVEAWAY_LOADING,
  INCR_PRODUCT_COUNT,
  LOAD_GIVEAWAYS,
  REM_PRODUCT,
  SET_ADRS_UPDATE_TEXT,
  SET_BUNDLES,
  SET_CREATION_DATE,
  SET_CUSTOM_TITLE,
  SET_ERPID,
  SET_ERP_EXCLUSION_REQUEST,
  SET_ERP_INTEGRATION_REQUEST,
  SET_EXTRACTED,
  SET_GIFT_BACK,
  SET_GIFT_TYPE,
  SET_GIVEAWAY,
  SET_GIVEAWAY_TITLE,
  SET_INFMAIL,
  SET_NOTE,
  SET_NUMERICAL_ID,
  SET_NUMERICAL_ID_LOADER,
  SET_PRODUCT,
  SET_PRODUCT_COUNT,
  SET_RECIPIENT,
  SET_RECIPIENT_TYPE,
  SET_RNDINFO,
  SET_SHIPMENT_ID,
  SET_SHIPMENT_SOURCE,
  SET_SHIPPING,
  SET_SHIPPING_ADDRESS,
  SET_SHOPID,
  SET_SHOULD_INF_MSG,
  SET_TITLE,
  SET_WHMAIL,
  SYNC_SHIPMENT_MSG,
  TEMP_ADDRESS_SET,
  TOGGLE_URGENT,
  UPDATE_PRODUCT
} from '../actions'

const initialState = {
  area: '',
  country: '',
  productFormCount: 0,
  id: undefined,
  doneLoading: false,
  who: null,
  hideAddress: false,
  outOfStock: false,
  urgent: false,
  title: '',
  customTitle: false,
  products: [],
  extracted: false,
  src: '',
  srcId: '',
  shopId: '',
  erpId: '',
  rndInfo: '',
  senderId: '',
  erpIntegrationRequested: false,
  erpPortConditionCode: 'DDP',
  erpTransportCode: 'WPX',
  erpExpeditionCode: 'AIR',
  erpBillingCode: '001',
  erpOrderNatureCode: 'FPR',
  numericalId: 0,
  giftType: 'regular',
  numericalIdLoading: false,
  creationDate: Date.now(),
  shippingDate: Date.now(),
  realShippingDate: null,
  note: '',
  warehouseNote: '',
  influencerNote: null,
  withInfluencerMsg: true,
  giveawayEnabled: false,
  giveawaysLoading: false,
  giveaways: [],
  giveawayId: '',
  giveawayTitle: '',
  giveawayRecipientType: '',
  selectedBundles: {},
  shippingAddress: null,
  cegidIntegrationScheduledDate: null,
  erpIntegrationExcluded: false
}
const removeProduct = (products, value) => {
  const result = []
  for (let index = 0; index < products.length; index++) {
    const element = products[index]
    if (element.cnt !== value) {
      result.push(element)
    }
  }
  return result
}

const updateProduct = (products, value) => {
  const result = []
  let val = value.value
  if (parseInt(val, 10) < 1) val = 1
  for (let index = 0; index < products.length; index++) {
    const element = products[index]
    if (element.cnt === value.index) {
      element[value.prop] = val
      result.push(element)
    } else {
      result.push(element)
    }
  }
  return result
}

const giftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DONE_LOADING: {
      return { ...state, doneLoading: action.value }
    }

    case SET_BUNDLES: {
      return {
        ...state,
        selectedBundles: action.value
      }
    }
    case SET_PRODUCT_COUNT: {
      return { ...state, productFormCount: action.value }
    }
    case SET_EXTRACTED: {
      return {
        ...state,
        extracted: action.value,
        realShippingDate: new Date('2022-08-01')
      }
    }
    case INCR_PRODUCT_COUNT: {
      return { ...state, productFormCount: state.productFormCount + 1 }
    }
    case SET_RNDINFO: {
      return { ...state, rndInfo: action.value }
    }
    case SET_GIFT_TYPE: {
      return { ...state, giftType: action.value }
    }
    case SET_GIFT_BACK: {
      const gift = action.value
      return {
        ...initialState,
        productFormCount: gift.giftedProducts?.length,
        urgent: gift.urgent,
        id: gift.id,
        numericalId: gift.numericalId,
        extracted: gift.extracted,
        hideAddress: gift.hideAddress,
        outOfStock: gift.outOfStock,
        title: gift.title,
        creationDate: gift.creationDate,
        products: gift.giftedProducts.map((p, i) => {
          return { ...p, cnt: i }
        }),
        src: gift.sender,
        erpIntegrationRequested: gift.erpIntegrationRequested,
        erpPortConditionCode: gift.erpPortConditionCode,
        erpTransportCode: gift.erpTransportCode,
        erpExpeditionCode: gift.erpExpeditionCode,
        erpBillingCode: gift.erpBillingCode,
        erpOrderNatureCode: gift.erpOrderNatureCode,
        giftType: gift.giftType,
        shippingDate: gift.shippingDate,
        realShippingDate: gift.realShippingDate,
        area: gift.area,
        country: gift.country,
        isStore: gift.isStore,
        erpId: gift.erpOrderId,
        shopId: gift.orderId,
        influencerNote: gift.marketingMessage,
        withInfluencerMsg: gift.marketingMessage !== '',
        note: gift.internalReason,
        giveawayId: gift.giveawayId ?? '',
        giveawayRecipientType: gift.isWinnerGift ? 'winner' : null,
        giveawayTitle:
          state.giveaways?.find((g) => g.id === gift.giveawayId)?.title ?? '',
        giveawaySelected: {
          label:
            state.giveaways?.find((g) => g.id === gift.giveawayId)?.title ?? '',
          value: state.giveaways?.find((g) => g.id === gift.giveawayId)?.id
        },
        who: {
          ...state.who,
          fullName: gift.shippingAddress?.fullName,
          addressStreet: gift.shippingAddress?.addressStreet,
          addressComplement: gift.shippingAddress?.addressComplement,
          addressExtended: gift.shippingAddress?.addressExtended,
          cityName: gift.shippingAddress?.cityName,
          zipCode: gift.shippingAddress?.zipCode,
          lifeCountry: gift.shippingAddress?.country,
          state: gift.shippingAddress?.state,
          publicEmail: gift.shippingAddress?.publicEmail,
          contactPhoneNumber: gift.shippingAddress?.contactPhoneNumber,
          erpCountry: gift.shippingAddress?.erpCountry
          // todo: erp country
        },
        selectedBundles: { id: gift.bundleId, title: gift.bundleTitle },
        erpCheckedPass: gift.erpCheckedPass,
        erpCheckedResultMessage: gift.erpCheckedResultMessage,
        cegidIntegrationScheduledDate: gift.cegidIntegrationScheduledDate,
        cegidIntegrationSubmittedDate: gift.cegidIntegrationSubmittedDate,
        cegidResponseDate: gift.cegidResponseDate,
        cegidResponseStatus: gift.cegidResponseStatus,
        erpIntegrationExcluded: gift.erpIntegrationExcluded,
        senderId: gift.senderId
      }
    }
    case SET_RECIPIENT: {
      return {
        ...state,
        who: action.value,
        area: action.value.area,
        giveawayEnabled: true
      }
    }
    case LOAD_GIVEAWAYS: {
      return { ...state, giveaways: action.value }
    }
    case GIVEAWAY_LOADING: {
      return { ...state, giveawaysLoading: action.value }
    }
    case SET_GIVEAWAY: {
      return {
        ...state,
        giveawayId: action.value,
        giveawaySelected: {
          label: state.giveaways.find((g) => g.id === action.value)?.title,
          value: action.value
        }
      }
    }
    case SET_GIVEAWAY_TITLE: {
      return { ...state, giveawayTitle: action.value }
    }
    case SET_RECIPIENT_TYPE: {
      return { ...state, giveawayRecipientType: action.value }
    }
    case SET_CUSTOM_TITLE: {
      return { ...state, customTitle: true }
    }
    case TOGGLE_URGENT: {
      return { ...state, urgent: !state.urgent }
    }
    case SET_TITLE: {
      return { ...state, title: action.value }
    }
    case SET_PRODUCT: {
      return { ...state, products: [...state.products, action.value] }
    }
    case REM_PRODUCT: {
      return { ...state, products: removeProduct(state.products, action.value) }
    }
    case UPDATE_PRODUCT: {
      return { ...state, products: updateProduct(state.products, action.value) }
    }
    case SET_SHOPID: {
      return { ...state, shopId: action.value }
    }
    case SET_ERPID: {
      return { ...state, erpId: action.value }
    }
    case SET_SHIPPING: {
      return {
        ...state,
        shippingDate: action.value,
        cegidIntegrationScheduledDate: action.value
      }
    }
    case SET_CREATION_DATE: {
      return { ...state, creationDate: action.value }
    }
    case SET_NOTE: {
      return { ...state, note: action.value }
    }
    case SET_WHMAIL: {
      return { ...state, warehouseNote: action.value }
    }
    case SET_INFMAIL: {
      return { ...state, influencerNote: action.value }
    }
    case SET_SHOULD_INF_MSG: {
      return { ...state, withInfluencerMsg: action.value }
    }
    case SET_SHIPMENT_SOURCE: {
      return {
        ...state,
        src: action.value
      }
    }
    case SET_SHIPMENT_ID: {
      return {
        ...state,
        srcId: action.value
      }
    }
    case SET_ERP_INTEGRATION_REQUEST: {
      return {
        ...state,
        erpIntegrationRequested: action.value
      }
    }
    case SET_ERP_EXCLUSION_REQUEST: {
      return {
        ...state,
        erpIntegrationExcluded: action.value
      }
    }
    case SET_SHIPPING_ADDRESS: {
      return { ...state, shippingAddress: action.value }
    }
    case SYNC_SHIPMENT_MSG: {
      return {
        ...state,
        withInfluencerMsg: !action.value.includes('warehouse')
          ? false
          : state.withInfluencerMsg,
        influencerNote: !action.value.includes('warehouse')
          ? null
          : state.influencerNote
      }
    }
    case TEMP_ADDRESS_SET: {
      return {
        ...state,
        who: {
          ...state.who,
          addressStreet: action.value.addressStreet,
          addressComplement: action.value.addressComplement,
          addressExtended: action.value?.addressExtended,
          publicEmail: action.value.publicEmail,
          state: action.value.state,
          fullName: action.value.fullName,
          cityName: action.value.city,
          zipCode: action.value.zip,
          lifeCountry: action.value.lifeCountry,
          contactPhoneNumber: action.value.contactPhoneNumber
        }
      }
    }
    case SET_NUMERICAL_ID: {
      return { ...state, numericalId: action.value }
    }
    case SET_NUMERICAL_ID_LOADER: {
      return { ...state, numericalIdLoading: action.value }
    }
    case CHANGE_HIDE_ADDRESS: {
      return { ...state, hideAddress: !state.hideAddress }
    }
    case CHANGE_OUT_OF_STOCK: {
      return { ...state, outOfStock: !state.outOfStock }
    }
    case SET_ADRS_UPDATE_TEXT:
      return { ...state, who: { ...state.who, [action.prop]: action.value } }
    case CLEANUP: {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default giftsReducer
