import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get() {
  return request({
    url: `${rootApiUrl}/products`,
    method: 'GET'
  })
}
function getGroupped() {
  return request({
    url: `${rootApiUrl}/products/groupped`,
    method: 'GET'
  })
}
function search(val) {
  return request({
    url: `${rootApiUrl}/products/${val}/search/`,
    method: 'GET'
  })
}

function toggleExclusion(id) {
  return request({
    url: `${rootApiUrl}/products/exclude/${id}`,
    method: 'POST'
  })
}
function toggleOrderable(id) {
  return request({
    url: `${rootApiUrl}/products/orderable/${id}`,
    method: 'POST'
  })
}

function getUrl() {
  return `${rootApiUrl}/products`
}

function deleteProduct(id) {
  return request({
    url: `${rootApiUrl}/products/delete/${id}`,
    method: 'DELETE'
  })
}

function addOrUpdate(body) {
  return request({
    url: `${rootApiUrl}/products/addOrUpdate`,
    method: 'POST',
    data: body
  })
}

function getExtract(type, since) {
  return request({
    url: `${rootApiUrl}/tools/extract`,
    method: 'POST', // Changed to POST
    responseType: 'blob',
    data: {
      // Using data for POST request
      type,
      since: since?.toISOString()
    }
  }).then((response) => {
    // Create blob with BOM
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([BOM, response.data], {
      type: 'text/csv;charset=utf-8'
    })

    // Get filename from header or use default
    const filename =
      response.headers['content-disposition']?.split('filename=')[1] ||
      `extract_${type}_${new Date().toISOString()}.csv`

    // Create download link and trigger download
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)

    return response
  })
}

const ProductsService = {
  get,
  getGroupped,
  getUrl,
  search,
  toggleExclusion,
  deleteProduct,
  addOrUpdate,
  toggleOrderable,
  getExtract
}

export default ProductsService
